import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAuth} from "../../hooks/useAuth";
import {Navigate, useNavigate} from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import classes from "./Home.module.scss";
import userService from "../../services/userServices";
import {format} from 'date-fns';
import Loading from "../../components/Loading/Loading";
import useFetch from "../../hooks/useFetch";

const Home = () => {
    const {user} = useAuth();
    const {logout} = useAuth();
    const navigate = useNavigate();
    const [query, setFilters] = useState({
        'search': '',
        'categories': [],
        'year': '',
        'order': '',
    });

    const [page, setPage] = useState(1);
    const {list, loading} = useFetch(query, page);
    const [videos, setVideos] = useState(list);
    const [years, setYears] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [search, setSearch] = useState('');
    const loader = useRef(null);

    useEffect(() => {
        setPage(prev => 1)
    }, [query]);

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setPage(prev => prev += 1);
        }
    }, []);


    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "100px",
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) observer.observe(loader.current);
    }, [handleObserver]);


    useEffect(() => {
        if (localStorage.getItem('from')) {
            navigate(localStorage.getItem('from'))
        }
        userService.getCategories().then(result => {
            setCategories(result.data.categories);
        }, error => {
            error.response.data.message === 'Unauthenticated' && logout()
        });
        userService.getYears().then(result => {
            setYears(result.data.years);
        }, error => {
            error.response.data.message === 'Unauthenticated' && logout()
        });
    }, []);


    const getVideos = () => {
        return list.map(video => {
            const dt = format(new Date(video.published_at.replace(/-/g, "/")), 'yyyy. MM. dd.');
            return <a href={"/video/" + video.url_slug}
                      className={"flex flex-col justify-between hover:scale-105 transition-all duration-500"}><img
                src={video.thumbnail_url}
                alt={video.title}
                className={"mb-2 lg:max-h-[200px] object-cover"}/>
                <div className={"flex flex-col justify-between"}>
                    <h3 className={"text-lg lg:text-sm text-brandSecondary font-bold border-l-[5px] border-brandSecondary mt-2 pl-1 " + classes["line-clamp"]}>{video.title}</h3>
                    <p className={"text-xs font-montserratM my-2 " + classes["line-clamp-3"]}>{video.description}</p>
                    <div className={"flex flex-row justify-between mb-5"}><p className={"text-xs italic"}>Előadó: <span
                        className={"font-bold"}>{video.presenter}</span></p> <p className={"text-xs italic"}>{dt}</p>
                    </div>
                </div>
            </a>
        })
    }

    const onChangeSearch = e => {
        const value = e.target.value;
        setSearch(value);

    }

    const getCategories = (/*identifier*/) => {

        return categories.map(category => {
            return <div className={"mb-2"}>
                <input type="checkbox" id={category.id /*+ identifier*/}
                       className={'accent-brandPrimary peer hidden'} onChange={(e) => {
                    const categoryArray = query.categories || [];
                    //console.log(category.id);
                    if (e.target.checked) {
                        categoryArray.push(category.id);
                    } else {
                        const index = categoryArray.indexOf(category.id);
                        if (index > -1) {
                            categoryArray.splice(index, 1);
                        }
                    }
                    setFilters(prevState => ({
                        ...prevState,
                        categories: categoryArray
                    }));
                }}/>
                <label htmlFor={category.id/* + identifier*/}
                       className={"font-base text-base font-black border cursor-pointer bg-white border-brandSecondary py-2.5 px-6 min-h-10 inline-block peer-checked:bg-brandPrimary peer-checked:text-buttonSecondary hover:bg-brandSecondary hover:text-white transition-all"}> {category.name}</label>
            </div>
        })
    }

    const getYears = () => {
        return years.map(year => {
            return <option value={year} className="text-brandSecondary font-base">{year}</option>
        })
    }

    const yearSelect = e => {
        const value = e.target.value;
        setFilters(prevState => ({
            ...prevState,
            year: value
        }));
    }

    const orderSelect = e => {
        const value = e.target.value;
        setFilters(prevState => ({
            ...prevState,
            order: value
        }));
    }

    if (!user) {
        return <Navigate to="/bejelentkezes"/>
            ;
    }

    return (
        <Layout padded={true}>
            <div className={"flex flex-col w-full " + classes["background"]}>

                <div className="bg-brandSecondary min-h-[150px] flex flex-col">
                    <div className="container mx-auto flex items-center justify-between h-full flex-grow">

                        <h1 className={"font-header uppercase text-6xl leading-6 font-bold text-buttonPrimary ml-64"}
                            id={"top"}>Videók</h1>
                        <a href={'#top'}
                           className="fixed bottom-5 right-5 bg-buttonSecondary px-5 pt-5 pb-6 flex items-center rounded-full text-[40px] leading-[20px] shadow-lg hover:bg-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24.311" height="15.691"
                                 viewBox="0 0 24.311 15.691">
                                <path id="Path_171" data-name="Path 171"
                                      d="M1979.882,1051.476l8.62,8.62,8.62-8.62"
                                      transform="translate(2000.657 1063.631) rotate(180)" fill="none"
                                      stroke="#ffd600"
                                      stroke-linecap="square" stroke-width="5"/>
                            </svg>
                        </a>
                    </div>
                </div>

                <div className={"container mx-auto mt-8"}>
                    <div className={"flex flex-col md:grid md:grid-cols-[1fr_5fr]"}>

                        <div className={"mr-10 mt-4 min-w-[161px] hidden md:flex md:flex-col"}>
                            <div className={'border-t-[3px] border-brandPrimary my-2'}/>
                            <h3 className={'font-header uppercase text-brandSecondary font-bold text-3xl mt-4 mb-3'}>Rendezés</h3>
                            <select name="order" id="order" onChange={orderSelect}
                                    className={'w-full text-xs text-[#959595] bg-white border border-brandSecondary p-3 mb-5 font-base'}>
                                <option value="descDate" className="font-base text-brandSecondary">Legújabb videó
                                </option>
                                <option value="ascDate" className="font-base text-brandSecondary">Legrégebbi videó
                                </option>
                                <option value="a-z" className="font-base text-brandSecondary">A-Z</option>
                                <option value="z-a" className="font-base text-brandSecondary">Z-A</option>
                            </select>
                            <h3 className={'font-header uppercase text-brandSecondary font-bold text-3xl pt-4 mb-3 border-t-[3px] border-brandPrimary'}>Kategóriák</h3>
                            <div className={'flex flex-col mb-5'}>
                                {getCategories()}
                            </div>
                            <h3 className={'font-header uppercase text-brandSecondary font-bold text-3xl pt-4 mb-3 border-t-[3px] border-brandPrimary'}>Év</h3>
                            <div className={'border-b-[3px] border-brandPrimary'}>
                                <select name="years" id="years" onChange={yearSelect}
                                        className={'w-full text-xs text-[#959595] bg-white border border-brandSecondary p-3 mb-5'}>
                                    <option value="" className={"font-base"}>Összes</option>
                                    {getYears()}
                                </select>
                            </div>
                        </div>

                        <div>
                            <div
                                className={"flex flex-col lg:grid lg:grid-cols-2 lg:gap-6 xl:grid xl:grid-cols-3 xl:gap-6 relative"}>
                                <div
                                    className={"lg:col-span-2 xl:col-span-3 pb-4 border-b-[3px] col-span-6 border-brandPrimary my-2"}>
                                </div>

                                {isLoading ? <div className={"lg:col-span-2 xl:col-span-3"}><Loading/></div> : <>
                                    {getVideos()}
                                </>}
                                {loading && <div className={"lg:col-span-2 xl:col-span-3"}><Loading/></div>}
                                <div className={'absolute bottom-0'} ref={loader}/>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </Layout>
    )
};

export default Home;
