import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAuth} from "../../hooks/useAuth";
import {Navigate, useSearchParams} from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import classes from "./Result.module.scss";
import userService from "../../services/userServices";
import {format} from 'date-fns';
import Loading from "../../components/Loading/Loading";
import searchIcon from "../../assets/magnifyingglass.svg";
import useFetch from "../../hooks/useFetch";

const Result = () => {
    const {user} = useAuth();
    const [q] = useSearchParams();
    const [videos, setVideos] = useState([]);
    const [years, setYears] = useState([]);
    const [categories, setCategories] = useState([]);
    const [maxVideos, setMaxVideos] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [query, setFilters] = useState({
        'search': q.get('search'),
        'categories': [],
        'year': '',
        'order': '',
    });

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const {list, loading} = useFetch(query, page);
    const loader = useRef(null);
    const [shareType, setShareType] = useState(null)

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setPage(prev => (prev += 1));
        }
    }, []);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) observer.observe(loader.current);
    }, [handleObserver]);
    useEffect(() => {
        setPage(1)
    }, [query]);

    useEffect(() => {
        userService.getCategories().then(result => {
            setCategories(result.data.categories);
        });
        userService.getYears().then(result => {
            setYears(result.data.years);
        });
    }, []);


    const getVideos = () => {
        /*if (list.length === 0) {
            return <p>Nincs találat!</p>;
        }*/
        return list.map(video => {
            const dt = format(new Date(video.published_at.replace(/-/g, "/")), 'yyyy. MM. dd.');
            return <a href={"/video/" + video.url_slug}
                      className={"font-base md:mb-4 flex flex-col xl:grid xl:grid-cols-[400px_auto]"} key={video.title}>
                <div className={"flex justify-center xl:block xl:mr-8 md:mb-2"}>
                    <img src={video.thumbnail_url}
                         alt={video.title}
                         className={"font-base mb-2 md:mb-0 xl:max-h-[200px] xl:mx-auto"}/>
                </div>
                <div className={"flex flex-col xl:self-center mb-5 md:mb-0"}>
                    <h3 className={"font-base text-lg lg:text-sm text-brandSecondary font-bold mb-auto border-l-[5px] border-brandSecondary pl-1 " + classes["line-clamp"]}>{video.title}</h3>
                    <p className={"font-base text-xs font-oswaldM my-2 " + classes["line-clamp-3"]}>{video.description}</p>
                    <p className={"font-base text-xs italic"}>Előadó: <span
                        className={"font-bold"}>{video.presenter}</span></p>
                    <p className={"font-base text-xs italic"}>{dt}</p></div>
            </a>
        })
    }

    const getCategories = (identifier) => {
        return categories.map(category => {
            return <div className={"mb-2"} key={category.id}>
                <input type="checkbox" id={category.id + identifier}
                       className={'accent-brandPrimary peer hidden '} onChange={(e) => {
                    const categoryArray = query.categories || [];
                    if (e.target.checked) {
                        categoryArray.push(category.id);
                    } else {
                        const index = categoryArray.indexOf(category.id);
                        if (index > -1) {
                            categoryArray.splice(index, 1);
                        }
                    }
                    setFilters(prevState => ({
                        ...prevState,
                        categories: categoryArray
                    }));
                }}/>
                <label htmlFor={category.id + identifier}
                       className={"font-base text-base font-black border cursor-pointer bg-white border-brandSecondary py-2.5 px-6 min-h-10 inline-block peer-checked:bg-brandPrimary peer-checked:text-buttonSecondary hover:bg-brandSecondary hover:text-white transition-all"}> {category.name}</label>
            </div>
        })
    }

    const getYears = () => {
        return years.map(year => {
            return <option value={year} key={year} className="text-brandSecondary font-base">{year}</option>
        })
    }

    const yearSelect = e => {
        const value = e.target.value;
        setFilters(prevState => ({
            ...prevState,
            year: value
        }));
    }

    const orderSelect = e => {
        const value = e.target.value;
        setFilters(prevState => ({
            ...prevState,
            order: value
        }));
    }

    const onChangeSearch = e => {
        const value = e.target.value;
        setSearch(value);

    }

    if (!user) {
        return <Navigate to="/bejelentkezes"/>
            ;
    }


    return (
        <Layout>
            <div className={"flex w-full " + classes["background"]}>
                <div className={"container mx-auto mt-11"}>
                    <div className={'md:hidden mb-8'}>
                        <h3 className={'uppercase text-brandPrimary font-bold text-lg mb-2'}>Keresés</h3>
                        <form action='/result' className={'md:hidden flex mb-5'}>
                            <input value={search} onChange={onChangeSearch} type="text" name='search'
                                   className='border border-gray rounded-md px-2.5 py-2 w-4/5 text-sm font-oswaldM outline-none focus:border-brandPrimary'
                                   placeholder='Keresés az oldalon...'/>
                            <button className={'btn-search -translate-x-1'}><img src={searchIcon} alt="Keresés"
                                                                                 className={'w-5 h-5'}/>
                            </button>
                        </form>
                    </div>
                    <div className={"flex flex-row"}>
                        <div className={"min-w-[218px]"}>
                            <div className={"block md:hidden"}>
                                <div className="accordion accordion-flush" id="accordionExample">
                                    <div className="accordion-item border-t-0 border-l-0 border-r-0 rounded-none ">
                                        <h2 id="Szűrők" className={"accordion-header"}>
                                            <button
                                                className={"accordion-button  uppercase text-brandPrimary font-bold text-lg mb-4 relative flex items-center w-full text-left border-0 rounded-none transition focus:outline-none " + classes['accordion-button']}
                                                type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="false"
                                                aria-controls="collapseOne">
                                                Szűrők
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse"
                                             aria-labelledby="headingOne"
                                             data-bs-parent="#accordionExample">
                                            <div className="accordion-body pb-6">
                                                <h3 className={'uppercase text-brandPrimary font-bold text-sm mb-1'}>Rendezés</h3>
                                                <select name="cars" id="cars" onChange={orderSelect}
                                                        className={'w-full text-xs font-oswaldM text-brandSecondary bg-white border border-lightgray rounded-md px-2 h-7 mb-5'}>
                                                    <option value="descDate">Legújabb videó</option>
                                                    <option value="ascDate">Legrégebbi videó</option>
                                                    <option value="a-z">A-Z</option>
                                                    <option value="z-a">Z-A</option>
                                                </select>
                                                <h3 className={'uppercase text-brandPrimary font-bold text-sm mb-1 pt-5 border-t-2 border-brandSecondary'}>Kategóriák</h3>
                                                <div className={'flex flex-col mb-5'}>
                                                    {getCategories('m')}
                                                </div>
                                                <h3 className={'uppercase text-brandPrimary font-bold text-sm mb-2 pt-5 border-t-2 border-brandSecondary'}>Év</h3>
                                                <div className={'border-b-2 border-brandSecondary'}>
                                                    <select name="cars" id="cars" onChange={yearSelect}
                                                            className={'w-full text-xs font-oswaldM text-brandSecondary bg-white border border-lightgray rounded-md px-2 h-7 mb-5'}>
                                                        <option value="">Összes</option>
                                                        {getYears()}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"min-w-[218px] hidden md:flex md:flex-col"}>
                                <div className={'border-t-[3px] border-brandPrimary my-2'}/>
                                <h3 className={'font-header uppercase text-brandSecondary font-bold text-3xl mt-4 mb-3'}>Rendezés</h3>
                                <select name="order" id="order" onChange={orderSelect}
                                        className={'w-full text-xs text-[#959595] bg-white border border-brandSecondary p-3 mb-5 font-base'}>
                                    <option value="descDate" className="font-base text-brandSecondary">Legújabb videó
                                    </option>
                                    <option value="ascDate" className="font-base text-brandSecondary">Legrégebbi videó
                                    </option>
                                    <option value="a-z" className="font-base text-brandSecondary">A-Z</option>
                                    <option value="z-a" className="font-base text-brandSecondary">Z-A</option>
                                </select>
                                <h3 className={'font-header uppercase text-brandSecondary font-bold text-3xl pt-4 mb-3 border-t-[3px] border-brandPrimary'}>Kategóriák</h3>
                                <div className={'flex flex-col mb-5'}>
                                    {getCategories('m')}
                                </div>
                                <h3 className={'font-header uppercase text-brandSecondary font-bold text-3xl pt-4 mb-3 border-t-[3px] border-brandPrimary'}>Év</h3>
                                <div className={'border-b-[3px] border-brandPrimary'}>
                                    <select name="years" id="years" onChange={yearSelect}
                                            className={'w-full text-xs text-[#959595] bg-white border border-brandSecondary p-3 mb-5'}>
                                        <option value="" className={"font-base"}>Összes</option>
                                        {getYears()}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={"mt-2 grow ml-10"}>
                            <h1 className={"font-header uppercase text-3xl leading-6 font-bold text-brandSecondary pt-[34px] border-t-[3px] border-brandPrimary"}>Keresési
                                eredmények</h1>
                            <p className={"text-brandSecondary font-base text-xs pb-8 border-b-[3px] border-brandPrimary mb-5 pt-[29px]"}>A
                                szabadszavas keresés a(z) '{q.get('search')}' kifejezésre az alábbi
                                találatokat eredményezte:</p>
                            <div
                                className={"flex flex-col"}>
                                {
                                    isLoading ? <Loading/> : <>
                                        {getVideos()}
                                    </>}
                                {loading && <div className={"lg:col-span-2 xl:col-span-3"}><Loading/></div>}
                                <div ref={loader}/>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </Layout>
    )
};

export default Result;
