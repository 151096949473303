import {useAuth} from "../../hooks/useAuth";
import {useMsal} from "@azure/msal-react";
import React, {useState} from "react";
import {useIsAuthenticated} from "@azure/msal-react";

const Nav = () => {

    const {logout} = useAuth();
    const {instance} = useMsal();
    const [navbarOpen, setNavbarOpen] = useState(false);
    const [search, setSearch] = useState('');
    const isAuthenticated = useIsAuthenticated();
    const onChangeSearch = e => {
        const value = e.target.value;
        setSearch(value);

    }

    function handleLogout(instance) {
        if (isAuthenticated) {
            instance.logoutPopup().catch(e => {
                console.error(e);
            }).then(logout)
        } else {
            logout();
        }
    }

    const {user} = useAuth();

    const handleToggle = () => {
        setNavbarOpen(prev => !prev)
    }


    return (
        <nav className="p-3 bg-navbar border-navbar min-h-[64px]">
            <div className={'container mx-auto flex items-center justify-between'}>

                <div className="flex my-0.25">
                    <a href="/">
                        <svg id="logo-jacsomedia-black" xmlns="http://www.w3.org/2000/svg" width="35" height="35"
                             viewBox="0 0 35 35">
                            <path id="logo-jacsomedia-black-2" data-name="logo-jacsomedia-black"
                                  d="M17.5,0A17.5,17.5,0,1,0,35,17.5,17.5,17.5,0,0,0,17.5,0M12.875,29.1H9.59v-19h3.285Zm4.177-6.334H13.767V10.1h3.285Zm4.188,0H17.955V10.1H21.24Zm4.17,0H22.125V10.1H25.41Z"
                                  transform="translate(0 0)" fill="#282828"/>
                        </svg>
                    </a>
                    {!user && <p className="font-bold font-header text-3xl ml-4">JACSOMEDIA VIDEO PORTAL</p>}
                </div>
                {user &&
                    <div className="flex flex-row">
                        <form action='/result' className={'hidden w-96 h-10 flex md:flex'}>
                            <input value={search} onChange={onChangeSearch} type="text" name='search'
                                   className='border-2 border-brandSecondary outline-none px-6 py-2 w-full h-10 text-xs focus:border-brandSecondary'
                                   placeholder='Keresés az oldalon'/>
                            <button className="btn-search flex justify-center items-center group">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="25" height="25"
                                     className="fill-current text-white stroke-current stroke-2 transition-transform duration-300 ease-in-out group-hover:scale-110">
                                    <path
                                        d="M 19 3 C 13.488281 3 9 7.488281 9 13 C 9 15.394531 9.839844 17.589844 11.25 19.3125 L 3.28125 27.28125 L 4.71875 28.71875 L 12.6875 20.75 C 14.410156 22.160156 16.605469 23 19 23 C 24.511719 23 29 18.511719 29 13 C 29 7.488281 24.511719 3 19 3 Z M 19 5 C 23.429688 5 27 8.570313 27 13 C 27 17.429688 23.429688 21 19 21 C 14.570313 21 11 17.429688 11 13 C 11 8.570313 14.570313 5 19 5 Z"/>
                                </svg>
                            </button>
                        </form>
                        <button onClick={() => {
                            handleLogout(instance)
                        }}
                                className={'font-base text-baseText ml-16 text-sm hidden md:block hover:text-white'}>
                            Kijelentkezés
                        </button>
                    </div>
                }
            </div>

        </nav>
    );
};

export default Nav;
